import React from 'react';
import Navbar from '../components/navbar';
import Contactcontent from '../components/Contactcontent';
import Footer from '../components/footer';

const Contactpage = () => {
  return (
    <div className="Contact">
          <Navbar transparentNavbar={false} type={"sticky"}/>
          <Contactcontent />
          <Footer />
    </div>
  );
}

export default Contactpage;