import '../css/Animations.css'; // Importa el archivo CSS personalizado
import {
  Typography,
  Button
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const HoverFlipCard = ({ initialFrontContent, svg, svgclassName,SecondFrontContent,SecondFrontParameters, initialBackContent, frontColor, backColor, size,ButtonContent, fontparametersback,fontparametersfront,link }) => {
    return (
      <div className={`flip-card ${size}`}>
        <div className="flip-card-inner">
          <div className={`front ${frontColor}`}>
            <div className={`${svgclassName}`}>{svg}</div>
            <Typography className={`${fontparametersfront}`} variant="h5">{initialFrontContent}</Typography>
            <Typography className={`${SecondFrontParameters}`}>{SecondFrontContent}</Typography>
          </div>
          <div className={`back ${backColor}`}>
            <Typography className={`${fontparametersback}`}>{initialBackContent}</Typography>
            <Link to={`${link}`}><Button>{ButtonContent}</Button></Link>
          </div>
        </div>
      </div>
    );
  };

export default HoverFlipCard;