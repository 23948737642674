import React from 'react';
import Navbar from '../components/navbar';
import Telcontent from '../components/Telcontent';
import Footer from '../components/footer';

const Telcopage = () => {
  return (
    <div className="Telco">
          <Navbar transparentNavbar={false} type={"sticky"} />
          <Telcontent />
          <Footer />
    </div>
  );
}

export default Telcopage;