import React from "react";
import { Typography } from "@material-tailwind/react";
import diagram from '../images/Diagram1.svg';
import diagram2 from '../images/Voxcom-Services.svg';
export default function Features() {
    return (
        <>
            <div className="w-11/12 mb-10 md:mb-0 md:w-3/4 mx-auto flex flex-col-reverse md:flex-row items-center">
                <div className="order-2 md:order-2 md:1/3 mt-3 mb-3">
                    <img src={diagram} alt="diagrama" className="w-full md:w-fit" />
                </div>
                <div className="order-1 md:order-1 md:w-2/3 md:text-left">
                    <Typography
                        variant="h2"
                        className="mt-0 md:mt-0 text-left text-Deep-Purple-500 font-sanz"
                    >
                        Conectamos tus ideas <br></br> a través de la tecnología.
                    </Typography>
                    <Typography
                        className="mt-3 md:mt-0 text-left md:w-3/4 font-mono text-xl"
                    >
                        La interconexión y adaptación de nuevas tecnologías para el desarrollo de la industria y del país, es
                        nuestro principal objetivo en Vox Comunicaciones, centrando nuestros esfuerzos en la generación de
                        valor para nuestros clientes. En Voxcom creemos firmemente en la tecnología como herramienta para la
                        optimización de los procesos productivos.
                    </Typography>
                </div>
            </div>
            <div className="w-full bg-gradient-to-r from-denim-700 to-denim-700 ">
                <div className="w-11/12 md:w-3/4 mx-auto flex flex-col-reverse md:flex-row items-center">
                    <div className="order-1 md:order-2 md:w-1/3 mt-3 mb-3">
                        <img src={diagram2} alt="diagrama2" className="w-full md:w-fit" />
                    </div>
                    <div className="order-2 md:order-1 md:w-2/3">
                        <Typography
                            variant="h2"
                            className="mt-2 md:mt-0 text-right text-white font-sanz"
                        >
                            Menos Problemas, Más Soluciones.
                        </Typography>
                        <Typography
                            className="mt-3 md:mt-0 text-right md:w-3/4 font-mono text-xl text-white float-right"
                        >
                            En Vox Comunicaciones estamos en constante crecimiento conforme avanzan las necesidades del país y la industria.
                            Prestando servicios y soluciones en diferentes áreas. Voxcom cuenta con un equipo multidisciplinario que le ha permitido
                            ampliar sus servicios en diferentes ámbitos como telecomunicaciones, TI, IoT, Ciberseguridad y Electricidad. Permitiendo
                            ser una empresa versátil y eficaz en todo tipo de requerimientos.
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    );
}