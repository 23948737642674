import React from 'react';
import Navbar from '../components/navbar';
import Aboutcontent from '../components/Aboutcontent';
import Footer from '../components/footer';

const Aboutpage = () => {
  return (
    <div className="About">
          <Navbar transparentNavbar={false} type={"sticky"} />
          <Aboutcontent />
          <Footer />
    </div>
  );
}

export default Aboutpage;