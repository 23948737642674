import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './assets/components/Homepage';
import Telecomunicaciones from './assets/components/Telcopage';
import TI from './assets/components/TIpage';
import IoT from './assets/components/IoTpage';
import Ciberseguridad from './assets/components/Ciberpage';
import Electricidad from './assets/components/Elecpage';
import About from './assets/components/Aboutpage';
import Contact from './assets/components/Contactpage';
import Error from './assets/components/Errorpage';
import UseCases from "./assets/components/Usecasespage";
import WorkWithUs from "./assets/components/Workwithuspage";
import ScrollToTop from "./assets/components/ScrollToTop";

class Router extends Component {

    render() {
        return (
            <BrowserRouter>
                { /*Configuracion de rutas*/}
                <ScrollToTop>
                    <Routes>
                        <Route exact path="/" Component={Home} />
                        <Route exact path="/home" Component={Home} />
                        <Route exact path="/Servicios-Telecomunicaciones" Component={Telecomunicaciones} />
                        <Route exact path="/Servicios-TI" Component={TI} />
                        <Route exact path="/Servicios-IoT" Component={IoT} />
                        <Route exact path="/Servicios-Ciberseguridad" Component={Ciberseguridad} />
                        <Route exact path="/Servicios-Electricidad" Component={Electricidad} />
                        <Route exact path="/About" Component={About} />
                        <Route exact path="/Contact" Component={Contact} />
                        <Route exact path="/Use-cases" Component={UseCases} />
                        <Route exact path="/Work-with-Us" Component={WorkWithUs} />
                        <Route path="*" Component={Error} />
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        );
    }
}

export default Router;