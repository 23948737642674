import React from 'react';
import Navbar from '../components/navbar';
import UsecasesContent from '../components/UsecasesContent';
import Footer from '../components/footer';

const Aboutpage = () => {
  return (
    <div className="About">
          <Navbar transparentNavbar={false} type={"sticky"} />
          <UsecasesContent />
          <Footer />
    </div>
  );
}

export default Aboutpage;