import React from 'react';
import Navbar from '../components/navbar';
import IoTcontent from '../components/IoTcontent';
import Footer from '../components/footer';

const IoTpage = () => {
  return (
    <div className="IoT">
          <Navbar transparentNavbar={false} type={"sticky"}/>
          <IoTcontent />
          <Footer />
    </div>
  );
}

export default IoTpage;