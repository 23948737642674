import React, {useState} from "react";
import Logo from '../images/Logo-Voxcom-White.png';
import { Link } from "react-router-dom";
import { EnvelopeIcon, MapPinIcon, MapIcon, PhoneIcon } from "@heroicons/react/24/solid";

export default function Footer() {

  const [year] = useState(new Date().getFullYear());

  return (
    <div className="relative mt-16 bg-blue-400">
      <svg
        className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-blue-400"
        preserveAspectRatio="none"
        viewBox="0 0 1440 54"
      >
        <path
          fill="currentColor"
          d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
        />
      </svg>
      <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
          <div className="md:max-w-md lg:col-span-2">
            <a
              href="/"
              aria-label="Go home"
              title="Voxcom S.A."
              className="inline-flex items-center"
            >
              <img src={Logo} alt="logo" />
            </a>
            <div className="mt-4 lg:max-w-sm">
              <p className="text-sm text-white">
                Voxcom es una empresa tecnológica con más de 15 años de experiencia en el desarrollo de proyectos de telecomunicaciones y TI.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-3">
            <div>
              <p className="font-semibold tracking-wide text-white">
                Datos de Contacto
              </p>
              <p className="mt-2 font-semibold tracking-wide text-white text-left">
                Casa Matriz Santiago
              </p>
              <ul className="mt-2 space-y-2 flex flex-col">
                <li>
                  <a
                    href="https://goo.gl/maps/NU8nWcRue1PRGKp9A?coh=178573&entry=tt"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400 flex float-left"
                  >
                    <MapPinIcon className="h-5 mr-3"></MapPinIcon>
                    M. Cousiño 82, Of.704
                  </a>
                </li>
                <li>
                  <a
                    href="tel:+56222529000"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400 flex float-left"
                  >
                    <PhoneIcon className="h-5 mr-3"></PhoneIcon>
                    +562 2252 9000
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:contacto@voxcom.cl"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400 flex float-left"
                  >
                    <EnvelopeIcon className="h-5 mr-3"></EnvelopeIcon>
                    Contacto@voxcom.cl
                  </a>
                </li>
                <li className="hidden">
                  <Link
                    to="/Contact"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400 flex float-left"
                  >
                    <MapIcon className="h-5 mr-3"></MapIcon>
                    Otras Sucursales
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="font-semibold tracking-wide text-white">
                Servicios
              </p>
              <ul className="mt-2 space-y-2">
                <li>
                  <Link
                    to="/Servicios-Telecomunicaciones"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400"
                  >
                    Telecomunicaciones
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Servicios-TI"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400"
                  >
                    TI
                  </Link>
                </li>
                <li>
                  <Link to="/Servicios-IoT"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400"
                  >
                    Internet of Things
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Servicios-Ciberseguridad"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400"
                  >
                    Ciberseguridad
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Servicios-Electricidad"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400 hidden"
                  >
                    Electricidad
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="font-semibold tracking-wide text-white">
                Links de interes
              </p>
              <ul className="mt-2 space-y-2">
                <li>
                  <Link
                    to="/About"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400"
                  >
                    Sobre Nosotros
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Contact"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400"
                  >
                    Contacto
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Use-Cases"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400 hidden"
                  >
                    Casos de éxito
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Work-With-Us"
                    className="transition-colors duration-300 text-gray-50 hover:text-orange-400 hidden"
                  >
                    Trabaja con Nosotros
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between md:items-auto md:pt-5 pb-10 border-t border-deep-purple-accent-200 sm:flex-row">
          <p className="text-sm text-white mb-3 mt-1 md:mb-0">
            © Copyright {year} Vox Comunicaciones S.A. Todos los derechos reservados.
          </p>
          <div className="flex items-center space-x-4 sm:mt-0">
            <a
              href="https://twitter.com/voxcomsa"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-colors duration-300 text-gray-100 hover:text-blue-200"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/voxcomcl/"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-colors duration-300 text-gray-100 hover:text-red-200"
            >
              <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                <circle cx="15" cy="15" r="4" />
                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
              </svg>
            </a>
            <a
              href="https://www.facebook.com/voxcomunicacionessa/?locale=es_LA"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-colors duration-300 text-gray-100 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/vox-comunicaciones-s-a/"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-colors duration-300 text-gray-100 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 50 50" fill="currentcolor" className="h-6">
                <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
              </svg>
            </a>
            <a
              href="https://www.youtube.com/@VoxcomS.A."
              target="_blank"
              rel="noopener noreferrer"
              className="transition-colors duration-300 text-gray-100 hover:text-red-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-6">
              <path d="M23.498,6.186c-0.276-1.039-1.089-1.858-2.122-2.136C19.505,3.546,12,3.546,12,3.546s-7.505,0-9.377,0.504   C1.591,4.328,0.778,5.146,0.502,6.186C0,8.07,0,12,0,12s0,3.93,0.502,5.814c0.276,1.039,1.089,1.858,2.122,2.136   C4.495,20.454,12,20.454,12,20.454s7.505,0,9.377-0.504c1.032-0.278,1.845-1.096,2.122-2.136C24,15.93,24,12,24,12   S24,8.07,23.498,6.186z M9.546,15.569V8.431L15.818,12L9.546,15.569z"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};