import React from "react";
import "../css/Animations.css";
import Voxcom from "../images/Voxcom-Logo-abajo.svg"
import Dtapia from "../images/Dangelo Tapia.jpg"
import Mtapia from "../images/Mario Tapia.jpg"
import CMoyano from "../images/Cristian Moyano.jpg"
import FRomero from "../images/Fernando Romero.jpg"
import JGarcia from "../images/Josue Garcia (2).jpg"

export default function Aboutcontent() {

    return (
        <>
            <div class="py-16 bg-white">
                <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
                    <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                        <div class="md:5/12 lg:w-5/12">
                            <img src={Voxcom} alt="Voxcom" width="540" height="" />
                        </div>
                        <div class="md:7/12 lg:w-6/12">
                            <h2 class="text-2xl text-gray-900 font-bold md:text-4xl">Voxcom es elegir Compromiso, innovación y Calidad. </h2>
                            <p class="mt-6 text-gray-600">Voxcom es una empresa creada en Febrero del 2007 por un grupo de destacados profesionales en el área de telecomunicaciones e informática. Nos especializamos en entregar soluciones integrales en Sistemas e Infraestructura de Telecomunicaciones y de Ingeniería de Software.</p>
                            <p class="mt-4 text-gray-600"> Contamos con un selecto grupo de ingenieros con mas de 15 años de experiencia en el sector de las Telecomunicaciones e Informática, manteniendo una actualización constante en base a los cambios y requerimientos actuales. Nos orientados a transformarnos en un socio tecnológico y estratégico de nuestros clientes, con un compromiso de mejora continua en la innovación, la eficiencia y la calidad en las soluciones implantadas.</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-white">
                <div className="pb-16 pl-4 pr-4 mx-auto ml-auto mr-auto bg-top bg-cover max-w-7xl md:px-24 lg:px-12 lg:py-10">
                    <div className="mb-10 ml-auto mr-auto bg-top bg-cover max-w-7xl md:mx-auto sm:text-center lg:max-w-2xl md:mb-16">
                        <div className="mb-6 ml-auto mr-auto font-sans tracking-tight text-gray-900 bg-top bg-cover max-w-7xl md:mx-auto">
                            <p className="inline max-w-lg text-2xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                                Nuestro Equipo
                            </p>
                        </div>
                        <p className="text-gray-600 md:text-lg -mb-5">
                            Conoce las personas que estan detras del Equipo Voxcom.
                        </p>
                    </div>
                    <div className="flex justify-center items-center">
                        <div className="w-6/7 grid gap-y-10 md:gap-x-36 lg:grid-cols-3 md:grid-cols-2">
                            <div>
                                <div className="relative overflow-hidden rounded-md shadow-lg group">
                                    <img
                                        className="object-cover w-full h-72 xl:h-80"
                                        src={Dtapia}
                                        alt="Dangelo Tapia"
                                    />
                                    <div className="overlay">
                                        <div className="content">
                                            <p className="mb-1 text-lg font-bold text-white">D'angelo Tapia</p>
                                            <p className="mb-4 text-xs text-blue-50">Fundador &amp; CEO</p>
                                            <div className="flex items-center justify-center space-x-3">
                                                <svg className="h-6" id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#f0f0f0" r="256" /><path d="m512 256c0 141.384-114.616 256-256 256s-256-114.616-256-256 256 0 256 0 193.761 0 256 0z" fill="#d80027" /><path d="m0 256c0-141.384 114.616-256 256-256v256s-166.957 0-256 0z" fill="#0052b4" /><path d="m152.389 89.043 16.577 51.018h53.643l-43.398 31.53 16.576 51.018-43.398-31.531-43.398 31.531 16.576-51.018-43.398-31.53h53.643z" fill="#f0f0f0" /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /></svg>
                                                <a
                                                    href="https://www.linkedin.com/in/d-angelo-tapia-pizarro-1008271a3/"
                                                    className="transition-colors duration-300 cursor-pointer hover:text-white text-blue-50"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-unsp-sanitized="clean"
                                                >
                                                    <svg className="h-6" viewBox="0 0 50 50" fill="currentColor">
                                                        <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className="relative overflow-hidden rounded-md shadow-lg group">
                                    <img
                                        className="object-cover w-full h-72 xl:h-80"
                                        src={Mtapia}
                                        alt="Mario Tapia"
                                    />
                                    <div className="overlay">
                                        <div className="content">
                                            <p className="mb-1 text-lg font-bold text-white">Mario Tapia</p>
                                            <p className="mb-4 text-xs text-blue-50">Fundador &amp; Manager Área TI</p>
                                            <div className="flex items-center justify-center space-x-3">
                                                <svg className="h-6" id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#f0f0f0" r="256" /><path d="m512 256c0 141.384-114.616 256-256 256s-256-114.616-256-256 256 0 256 0 193.761 0 256 0z" fill="#d80027" /><path d="m0 256c0-141.384 114.616-256 256-256v256s-166.957 0-256 0z" fill="#0052b4" /><path d="m152.389 89.043 16.577 51.018h53.643l-43.398 31.53 16.576 51.018-43.398-31.531-43.398 31.531 16.576-51.018-43.398-31.53h53.643z" fill="#f0f0f0" /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /></svg>
                                                <a  hidden
                                                    href="#/"
                                                    className="transition-colors duration-300 cursor-pointer hover:text-white text-blue-50"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-unsp-sanitized="clean"
                                                >
                                                    <svg className="h-6" viewBox="0 0 50 50" fill="currentColor">
                                                        <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className="relative overflow-hidden rounded-md shadow-lg group">
                                    <img
                                        className="object-cover w-full h-72 xl:h-80"
                                        src={CMoyano}
                                        alt="Cristian Moyano"
                                    />
                                    <div className="overlay">
                                        <div className="content">
                                            <p className="mb-1 text-lg font-bold text-white">Cristian Moyano</p>
                                            <p className="mb-4 text-xs text-blue-50">Global Product Manager</p>
                                            <div className="flex items-center justify-center space-x-3">
                                                <svg className="h-6" id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#f0f0f0" r="256" /><path d="m512 256c0 141.384-114.616 256-256 256s-256-114.616-256-256 256 0 256 0 193.761 0 256 0z" fill="#d80027" /><path d="m0 256c0-141.384 114.616-256 256-256v256s-166.957 0-256 0z" fill="#0052b4" /><path d="m152.389 89.043 16.577 51.018h53.643l-43.398 31.53 16.576 51.018-43.398-31.531-43.398 31.531 16.576-51.018-43.398-31.53h53.643z" fill="#f0f0f0" /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /></svg>
                                                <a
                                                    href="https://www.linkedin.com/in/cristian-alberto-moyano-maturana-b89b886a/"
                                                    className="transition-colors duration-300 cursor-pointer hover:text-white text-blue-50"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-unsp-sanitized="clean"
                                                >
                                                    <svg className="h-6" viewBox="0 0 50 50" fill="currentColor">
                                                        <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div>
                                <div className="relative overflow-hidden rounded-md shadow-lg group">
                                    <img
                                        className="object-cover w-full h-72 xl:h-80"
                                        src={FRomero}
                                        alt="Fernando Romero"
                                    />
                                    <div className="overlay">
                                        <div className="content">
                                            <p className="mb-1 text-lg font-bold text-white">Fernando Romero</p>
                                            <p className="mb-4 text-xs text-blue-50">Director Negocios USA</p>
                                            <div className="flex items-center justify-center space-x-3">
                                                <svg className="h-6" id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#f0f0f0" r="256" /><g fill="#d80027"><path d="m244.87 256h267.13c0-23.106-3.08-45.49-8.819-66.783h-258.311z" /><path d="m244.87 122.435h229.556c-15.671-25.572-35.708-48.175-59.07-66.783h-170.486z" /><path d="m256 512c60.249 0 115.626-20.824 159.356-55.652h-318.712c43.73 34.828 99.107 55.652 159.356 55.652z" /><path d="m37.574 389.565h436.852c12.581-20.529 22.338-42.969 28.755-66.783h-494.362c6.417 23.814 16.174 46.254 28.755 66.783z" /></g><path d="m118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037c-19.106 15.915-35.852 34.561-49.652 55.337h7.475l-13.813 10.035c-2.152 3.59-4.216 7.237-6.194 10.938l6.596 20.301-12.306-8.941c-3.059 6.481-5.857 13.108-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444c-1.301 10.458-1.979 21.11-1.979 31.921h256c0-141.384 0-158.052 0-256-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm100.115 100.083-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm0-74.574 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822z" fill="#0052b4" /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /></svg>
                                                <a
                                                    href="https://www.linkedin.com/in/fernando-romero-89307a14/"
                                                    className="transition-colors duration-300 cursor-pointer hover:text-white text-blue-50"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-unsp-sanitized="clean"
                                                >
                                                    <svg className="h-6" viewBox="0 0 50 50" fill="currentColor">
                                                        <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="relative overflow-hidden rounded-md shadow-lg group">
                                    <img
                                        className="object-cover w-full h-72 xl:h-80"
                                        src={JGarcia}
                                        alt="Josue Garcia"
                                    />
                                    <div className="overlay">
                                        <div className="content">
                                            <p className="mb-1 text-lg font-bold text-white">Josue Garcia</p>
                                            <p className="mb-4 text-xs text-blue-50">Director Negocios México</p>
                                            <div className="flex items-center justify-center space-x-3">
                                                <svg className="h-11" id="Layer_1" enable-background="new 0 0 100 100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m50 20.044c-5.046 0-9.802 1.26-13.978 3.473l-2 27.158 2 25.808c4.176 2.213 8.932 3.473 13.978 3.473s9.801-1.259 13.977-3.472l2-25.808-2-27.158c-4.175-2.214-8.931-3.474-13.977-3.474z" fill="#fff" /><path d="m79.956 50c0-11.458-6.469-21.43-15.945-26.466v52.932c9.475-5.036 15.945-15.008 15.945-26.466z" fill="#e90000" /><path d="m63.978 76.483c.011-.006.022-.011.033-.017v-52.932c-.011-.006-.022-.011-.033-.017z" fill="#128807" /><path d="m20.044 50c0 11.458 6.469 21.429 15.944 26.466v-52.932c-9.474 5.037-15.944 15.008-15.944 26.466z" fill="#006847" /><path d="m35.989 23.534v52.932c.011.006.022.011.033.017v-52.966c-.011.006-.022.011-.033.017z" fill="#f93" /></g><g fill="#88431f"><path d="m45.622 44.967c1.528-3.188 3.493-.742 3.493-.742s.306-2.533 2.576-1.572 2.686 3.996 2.686 3.996-.196-.197-1.725-.197-1.858.603-1.572 1.305c.3.737 2.926 1.621 2.926 1.621l-2.969 1.397s-1.419-1.812-2.926-2.05-1.092-2.36-1.135-3.408c-.044-1.049-1.354-.35-1.354-.35z" /><path d="m59.518 48.047c0 5.248-4.27 9.518-9.518 9.518s-9.518-4.27-9.518-9.518c0-.829.671-1.5 1.5-1.5s1.5.671 1.5 1.5c0 3.594 2.924 6.518 6.518 6.518s6.518-2.924 6.518-6.518c0-.829.671-1.5 1.5-1.5s1.5.671 1.5 1.5z" /></g></g></svg>
                                                <a  hidden
                                                    href="#/"
                                                    className="transition-colors duration-300 cursor-pointer hover:text-white text-blue-50"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-unsp-sanitized="clean"
                                                >
                                                    <svg className="h-6" viewBox="0 0 50 50" fill="currentColor">
                                                        <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-auto "></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
