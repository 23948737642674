import React from 'react';
import Navbar from '../components/navbar';
import Cibercontent from '../components/Cibercontent';
import Footer from '../components/footer';

const Ciberpage = () => {
  return (
    <div className="Ciberseg">
          <Navbar transparentNavbar={false} type={"sticky"}/>
          <Cibercontent />
          <Footer />
    </div>
  );
}

export default Ciberpage;