import React, { useState } from "react"; // Agregar useState
import { Swiper, SwiperSlide } from "swiper/react";
import { Typography } from "@material-tailwind/react";
import Modal from 'react-modal';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import welcome from '../videos/Welcome.mp4';
import libelium from '../videos/Libelium.mp4'
import Blockchain from '../videos/Blockchain.mp4'
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";
import { PlayCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
export default function Carousel() {

  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const closeModal = () => {
    setModalIsOpen2(false);
  };

  return (
    <>
      <Swiper
        effect="fade"
        slidesPerView={1}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 7000,
          disableOnInteraction: true,
        }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        loop
        className="h-screen"
      >

        <SwiperSlide>
          <div className="relative w-full h-full flex flex-col-reverse md:flex-row items-center ">
            <video autoPlay loop muted className="absolute inset-0 w-full h-full object-cover">
              <source src={welcome} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="absolute inset-0 flex flex-col items-center md:flex-row justify-center bg-black bg-opacity-50">
              <div className="md:items-start md:mx-auto md:w-1/3 items-center mx-4 lg:mx-auto order-1 lg:w-1/3 lg:order-2 md:mt-4 md:mb-2"></div>
              <div className="flex flex-col lg:w-3/5 xl:w-2/5 text-left mx-4 mb-4 lg:mx-auto">
                <Typography
                  variant="h1"
                  color="white"
                  className=" text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-4 ">
                  Voxcom tu socio perfecto en tecnología
                </Typography>
                <Typography
                  variant="paragraph"
                  color="white"
                  className=" font-mono text-base md:text-lg lg:text-xl ">
                  Estamos dispuestos a ser tu aliado completo en tecnología y transformación digital. No solo desarrollamos software, también somos expertos en redes, ciberseguridad, cloud e IoT. Ofreciendo soluciones integrales para tus proyectos, desde el inicio hasta el final.
                </Typography>
                <Typography
                  variant="paragraph"
                  color="white"
                  className=" font-mono text-base md:text-lg lg:text-xl mt-2">
                  En Voxcom transformaremos tus ideas en realidad.
                </Typography>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative w-full h-full flex flex-col-reverse md:flex-row items-center ">
            <video autoPlay loop muted className="absolute inset-0 w-full h-full object-cover">
              <source src={libelium} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="absolute inset-0 flex flex-col items-center md:flex-row justify-center bg-black bg-opacity-50">
              <button className="md:items-start md:mx-auto md:w-1/3 items-center mx-4 lg:mx-auto order-1 lg:w-1/3 lg:order-2 md:mt-4 md:mb-2" onClick={() => setModalIsOpen2(true)}>
                <PlayCircleIcon className="w-10 md:w-16 xl:w-20 " color="white" />
              </button>
              <div className="flex flex-col lg:w-3/5 xl:w-2/5 text-left mx-4 mb-4 lg:mx-auto">
                <Typography
                  variant="h1"
                  color="white"
                  className=" text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-4 ">
                  Voxcom integra Libelium en Chile
                </Typography>
                <Typography
                  variant="paragraph"
                  color="white"
                  className=" font-mono text-base md:text-lg lg:text-xl ">
                  Libelium One ya está en Chile, El mejor dispositivo IoT para la gestión del agua, la digitalización de la industria, la monitorización agrícola o las ciudades inteligentes. Confía en One para gestionar tu campo, optimizar el sistema de agua potable de tu ciudad o para aumentar el rendimiento de tus cultivos de la forma más sencilla.
                </Typography>
                <Typography
                  variant="paragraph"
                  color="white"
                  className=" font-mono text-base md:text-lg lg:text-xl mt-2">
                  Descubre la gama de productos IoT que Libelium ofrece para ti de la mano de Voxcom.
                </Typography>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative w-full h-full flex flex-col-reverse md:flex-row items-center ">
            <video autoPlay loop muted className="absolute inset-0 w-full h-full object-cover">
              <source src={Blockchain} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="absolute inset-0 flex flex-col items-center md:flex-row justify-center bg-black bg-opacity-50">
              <button className="md:items-start md:mx-auto md:w-1/3 items-center mx-4 lg:mx-auto order-1 lg:w-1/3 lg:order-2 md:mt-4 md:mb-2" onClick={() => setModalIsOpen2(true)}>
                <PlayCircleIcon className="hidden w-10 md:w-16 xl:w-20 " color="white" />
              </button>
              <div className="flex flex-col lg:w-3/5 xl:w-2/5 text-left mx-4 mb-4 lg:mx-auto">
                <Typography
                  variant="h1"
                  color="white"
                  className=" text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-4 ">
                  Voxcom innova aplicando la tecnología Blockchain
                </Typography>
                <Typography
                  variant="paragraph"
                  color="white"
                  className=" font-mono text-base md:text-lg lg:text-xl ">
                 Integramos la tecnología blockchain para asegurar la seguridad, transparencia y eficiencia en tus operaciones. Ofrecemos asesoría e implementación especializada mediante el formato MVP (Producto Mínimo Viable) en todos nuestros servicios.
                </Typography>
                <Typography
                  variant="paragraph"
                  color="white"
                  className=" font-mono text-base md:text-lg lg:text-xl mt-2">
                 Trabajamos para impulsar la transformación digital y fortalecer la confianza en cada etapa de tu proyecto
                </Typography>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={() => setModalIsOpen2(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 1000,
          },
          content: {
            width: "70%",
            height: "70%",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            background: "transparent",
          },
        }}
      >
        <button className="absolute top-0 right-0" onClick={closeModal}>
          <XCircleIcon className="w-7" color="white" />
        </button>
        <iframe style={{ width: "100%", height: "100%" }} src="https://www.youtube.com/embed/dh5YMuN0fgs?si=4Ic0o4Gy7Ajum_1Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Modal>
    </>
  );
}