import React from "react";
import { Typography } from "@material-tailwind/react";
import Libelium from '../images/Libelium-logo.svg'; 
import Amazon from '../images/Amazon-logo.svg';
import Zebra from '../images/Zebra-logo.svg';
import Telefonica from '../images/Movistar-logo.svg';
import Gestel from '../images/Gestel-logo.svg';
import Elecpa from '../images/Elecpa-logo.svg';

export default function Partners (){

function Container (props) {
  return (
    <div
      className={`container p-8 mx-auto xl:px-0 ${
        props.className ? props.className : ""
      }`}>
      {props.children}
    </div>
  );
}
function AmazonLogo() {
    return (
        <img src={Amazon} alt="Amazon"></img>
    );
  }
  function ZebraLogo() {
    return (
        <img src={Zebra} alt="Zebra"></img>
    );
  }
  
  function TelefonicaLogo() {
    return (
      <img src={Telefonica} alt="Telefonica"></img>
    );
  }
  
  function GestelLogo() {
    return (
      <img src={Gestel} alt="Gestel"></img>
    );
  }
  
  function LibeliumLogo() {
    return (
        <img src={Libelium} alt="Libelium"></img>
    );
  }

  function ElecpaLogo() {
    return (
        <img src={Elecpa} alt="Elecpa"></img>
    );
  }

    return(
        <>
        <Container>
        <div className="flex flex-col justify-center">
          <div className="text-xl text-center text-gray-700 dark:text-white">
            <Typography
            variant="h2"
            className="mb-2 md:mt-0 text-Deep-Purple-500 font-sanz">
                Nuestros Principales Socios Comerciales
            </Typography>
          </div>

          <div className="flex flex-wrap justify-center gap-5 mt-10 md:justify-around">
            <div className="pt-2 text-gray-400 dark:text-gray-400">
              <AmazonLogo />
            </div>
            <div className="text-gray-400 dark:text-gray-400">
              <ZebraLogo />
            </div>
            <div className="text-gray-400 dark:text-gray-400">
              <LibeliumLogo />
            </div>
            <div className="pt-1 text-gray-400 dark:text-gray-400">
              <TelefonicaLogo />
            </div>
            <div className="text-gray-400 dark:text-gray-400">
              <GestelLogo />
            </div>
            <div className="text-gray-400 dark:text-gray-400">
              <ElecpaLogo />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}



