import React from 'react';
import Navbar from '../components/navbar';
import Elecontent from '../components/Elecontent';
import Footer from '../components/footer';

const Elecpage = () => {
  return (
    <div className="Elec">
          <Navbar transparentNavbar={false} type={"sticky"}/>
          <Elecontent />
          <Footer />
    </div>
  );
}

export default Elecpage;