import React, { useEffect, useState } from "react";
import {
  Navbar,
  Typography,
  Button,
  IconButton,
  Collapse,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Logo from '../images/Voxcom-Logo.svg';
import LogoWhite from '../images/Voxcom-Logo-White.svg';


export default function Menu({ transparentNavbar, type, state }) {
  const [openNav, setOpenNav,] = React.useState(false);
  const [isNavbarTransparent, setIsNavbarTransparent] = useState(state);
  const [isHovered, setIsHovered] = useState(false);
  const whiteLogo = LogoWhite;
  const colorLogo = Logo;
  const currentLogo = isNavbarTransparent ? isHovered ? colorLogo : whiteLogo : colorLogo;

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;
      setIsNavbarTransparent(transparentNavbar && scrollPosition < threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  },);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mb-4 mt-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color={isNavbarTransparent ? isHovered ? "blue-gray" : "white" : "blue-gray"}
        className="p-1 font-normal"
      >
        <Link to="/Servicios-Telecomunicaciones" className="flex items-center">
          Telecomunicaciones
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color={isNavbarTransparent ? isHovered ? "blue-gray" : "white" : "blue-gray"}
        className="p-1 font-normal"
      >
        <Link to="/Servicios-TI" className="flex items-center">
          TI
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color={isNavbarTransparent ? isHovered ? "blue-gray" : "white" : "blue-gray"}
        className="p-1 font-normal"
      >
        <Link to="/Servicios-IoT" className="flex items-center">
          IoT
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color={isNavbarTransparent ? isHovered ? "blue-gray" : "white" : "blue-gray"}
        className="p-1 font-normal"
      >
        <Link to="/Servicios-Ciberseguridad" className="flex items-center">
          Ciberseguridad
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color={isNavbarTransparent ? isHovered ? "blue-gray" : "white" : "blue-gray"}
        className="p-1 font-normal hidden"
      >
        <Link to="/Servicios-Electricidad" className="flex items-center hidden">
          Electricidad
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color={isNavbarTransparent ? isHovered ? "blue-gray" : "white" : "blue-gray"}
        className="p-1 font-normal"
      >
        <Link to="/About" className="flex items-center">
          Nosotros
        </Link>
      </Typography>
    </ul>
  );

  return (
    <>
      <Navbar className={`inset-0 ${type} shadow-none backdrop-blur-none z-10 h-max max-w-full rounded-none border-0 py-2 px-6 lg:px-10 lg:py-4 ${isNavbarTransparent ? "bg-transparent transition ease-in-out duration-300" : "bg-white transparent transition ease-in-out duration 300"} ${isHovered && "bg-white"}`}
        elevation={isNavbarTransparent ? 0 : 4}
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseLeave}
        >
        <div className="flex items-center justify-between text-blue-gray-900">
          <Link to="/home">
            <img src={currentLogo} alt="logo" className="h-[5rem]"></img>
          </Link>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>
            <Link to="/Contact">
              <Button
                variant="gradient"
                size="sm"
                className="hidden lg:inline-block"
              >
                <span>Contacto</span>

              </Button>
            </Link>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke={isNavbarTransparent ? isHovered ? "currentcolor" : "white" : "currentcolor"}
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke={isNavbarTransparent ? isHovered ? "currentcolor" : "white" : "currentcolor"}
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav}>
          {navList}
          <Link to="/Contact">
            <Button variant="gradient" size="sm" fullWidth className="mb-2">
              <span>Contacto</span>
            </Button>
          </Link>
        </Collapse>
      </Navbar>
    </>
  );
}