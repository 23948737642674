import React from 'react';
import Errorcontent from '../components/Errorcontent';

const Errorpage = () => {
  return (
    <div className="IoT">
          <Errorcontent />
    </div>
  );
}

export default Errorpage;