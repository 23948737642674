import React from 'react';
import Navbar from '../components/navbar';
import WorkContent from '../components/WorkContent';
import Footer from '../components/footer';

const Aboutpage = () => {
  return (
    <div className="About">
          <Navbar transparentNavbar={false} type={"sticky"} />
          <WorkContent />
          <Footer />
    </div>
  );
}

export default Aboutpage;