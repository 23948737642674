import React from "react";
import { useEffect, useState } from 'react';
import { EnvelopeIcon, PhoneIcon, InboxArrowDownIcon } from "@heroicons/react/24/outline";
import { Input, Textarea, Button } from "@material-tailwind/react";
import options from '../Data/Servicios.json';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

export default function Contactcontent() {

    const [formData, setFormData] = useState({ user_name: '', user_lastname: '', user_email: '', user_phone: '', vox_area: '', vox_service: '', message: '' });

    const handleChange = (e) => {
        const { name, value } = e.target || e;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const form = document.querySelector('form');

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
                setFormData({ user_name: '', user_lastname: '', user_email: '', user_phone: '', vox_area: '', vox_service: '', message: '' })
                if (result.text === 'OK') {
                    toast.success('Mensaje enviado correctamente', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    form.reset();
                    setSelectedOption(null);
                    setSubSelectedOption(null);
                    localStorage.removeItem('ESP');
                    localStorage.removeItem('SER');
                }
            })
            .catch((error) => {
                console.log(error.text);
            });
    };

    function handleChangeEspecialidad(selEspecialidad) {
        var espSel = selEspecialidad.value;
        setSelectedOption(selEspecialidad);
        var espIndex = options.findIndex(({ value }) => value === espSel);
        var arrServicio = options[espIndex].servicio;
        setSubSelectedOption(arrServicio[0]);

    };

    useEffect(() => {
        const formElement = document.getElementById('form');
        if (formElement) {
            formElement.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const [selectedOption, setSelectedOption] = useState(null);
    const [subSelectedOption, setSubSelectedOption] = useState(null);

    var esp = localStorage.getItem("ESP");
    var ser = localStorage.getItem("SER");

    useEffect(() => {
        var espDefault = options.find(({ value }) => value === esp);
        if (espDefault) {
            setSelectedOption(espDefault);
            var espIndex = options.findIndex(({ value }) => value === esp);
            var arrServicio = options[espIndex].servicio;
            if (arrServicio) {
                var serDefault = arrServicio.find(({ value }) => value === ser);
                setSubSelectedOption(serDefault);
            }
        }
    }, [esp, ser]);

    console.log(localStorage.getItem("ESP"));
    console.log(selectedOption);

    console.log(localStorage.getItem("SER"));
    console.log(subSelectedOption);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '1px solid #e2e8f0',
            borderRadius: '0.375rem',
            width: '100%',
            fontSize: '0.875rem',
            position: 'relative',
        }),
        indicatorSeparator: () => ({}),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#DCE0E6',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid #e2e8f0',
            borderTop: 'none',
            borderRadius: '0 0 0.375rem 0.375rem',
            backgroundColor: '#ffffff',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        }),
        option: (provided, state) => ({
            ...provided,
            padding: '0.75rem 1rem',
            '&:hover': {
                backgroundColor: '#edf2f7',
                color: '#455a64'
            },
            '&:focus': {
                backgroundColor: '#edf2f7',
            },
            backgroundColor: state.isSelected ? '#455a64' : 'transparent',
            color: state.isSelected ? '#ffffff' : '#455a64',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#607d8b',
        }),
    };

    console.log("SelectedOption")
    console.log(selectedOption);
    console.log("SubSelectedOption")
    console.log(subSelectedOption?.label);

    return (
        <>
            <ToastContainer />
            <section id="contact" className="bg-white dark:bg-gray-900">
                <div className="container px-6 py-12 mx-auto">
                    <div>
                        <p className="font-medium text-blue-500 dark:text-blue-400">Contactanos</p>

                        <h1 className="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">Ponte en contacto con Nosotros</h1>

                        <p className="mt-3 text-gray-500 dark:text-gray-400">Nuestro Equipo esta siempre disponible para ti</p>
                    </div>

                    <div className="grid grid-cols-1 gap-12 mt-10 md:grid-cols-2 lg:grid-cols-3">
                        <div>
                            <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                            </span>

                            <h2 className="mt-4 text-lg font-medium text-gray-800 dark:text-white">Email</h2>
                            <p className="mt-2 text-gray-500 dark:text-gray-400">Nuestro Equipo esta aquí para ayudarte.</p>
                            <p className="mt-2 text-blue-500 dark:text-blue-400">Contacto@voxcom.cl</p>
                        </div>

                        <div>
                            <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>
                            </span>

                            <h2 className="mt-4 text-lg font-medium text-gray-800 dark:text-white">Oficina</h2>
                            <p className="mt-2 text-gray-500 dark:text-gray-400">Ven a nuestras oficinas principales.</p>
                            <p className="mt-2 text-blue-500 dark:text-blue-400">Matias Cousiño 82, Oficina 704. Santiago, Chile.</p>
                        </div>

                        <div>
                            <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                </svg>
                            </span>

                            <h2 className="mt-4 text-lg font-medium text-gray-800 dark:text-white">Teléfono</h2>
                            <p className="mt-2 text-gray-500 dark:text-gray-400">Lun-Vie de 8am a 5pm.</p>
                            <p className="mt-2 text-blue-500 dark:text-blue-400">+56 (2) 2252-9000</p>
                        </div>
                    </div>
                    <div className="flex w-full mt-20 grid grid-cols-1 gap-12 lg:col-span-4 md:grid-cols-3">
                        <div>
                            <h2 className="font-medium text-gray-800 dark:text-white">Santiago, Chile.</h2>
                            <p className="mt-2 text-gray-500 dark:text-gray-400"><EnvelopeIcon className="w-5 mr-2 inline-flex" />Cmoyano@voxcom.cl</p>
                            <p className="mt-2 text-gray-500 dark:text-gray-400"><PhoneIcon className="w-5 mr-1 inline-flex" />+56 (9) 9732 8902</p>
                        </div>
                        <div>
                            <h2 className="font-medium text-gray-800 dark:text-white">Ciudad de México, México.</h2>
                            <p className="mt-2 text-gray-500 dark:text-gray-400"><EnvelopeIcon className="w-5 mr-2 inline-flex" />Josue.garcia@voxcom.cl</p>
                            <p className="mt-2 text-gray-500 dark:text-gray-400"><PhoneIcon className="w-5 mr-1 inline-flex" />+52 (55) 4177 9236</p>
                        </div>
                        <div>
                            <h2 id="form" className="font-medium text-gray-800 dark:text-white">California, Los Angeles, USA</h2>
                            <p className="mt-2 text-gray-500 dark:text-gray-400"><EnvelopeIcon className="w-5 mr-2 inline-flex" />Fernando.romero@voxcom.cl</p>
                            <p className="mt-2 text-gray-500 dark:text-gray-400"><PhoneIcon className="w-5 mr-1 inline-flex" />+1 (310) 951 1864</p>
                        </div>
                    </div>
                </div>
            </section>
            <div >
                <section className="bg-white dark:bg-gray-900">
                    <div className="container px-6 py-12 mx-auto">
                        <p className="font-medium text-blue-500 dark:text-blue-400">Formulario de Contacto</p>
                        <h1 className="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">¿Necesitas ayuda con algún proyecto o servicio?</h1>
                        <p className="mt-3 text-gray-500 dark:text-gray-400">A continuacion puedes llenar nuestro formulario de contacto</p>
                        <div className="w-full mx-auto md:w-3/4 ">
                            <form onSubmit={(e) => sendEmail(e)} className="mt-10 mx-auto grid grid-cols-1 gap-12 lg:col-span-2 md:grid-cols-2" >
                                <Input type="text" required label="Nombre" name="user_name" onChange={(e) => handleChange(e, "user_name")} value={formData.user_name} />
                                <Input type="text" required label="Apellido" name="user_lastname" onChange={(e) => handleChange(e, "user_lastname")} value={formData.user_lastname} />
                                <Input type="email" required label="Correo Eléctronico" name="user_email" onChange={(e) => handleChange(e, "user_email")} value={formData.user_email} />
                                <Input type="tel" pattern="^(?:\+56|0056|56)?\s?(?:\(?[0-9]{1,3}\)?\s?)(?:[0-9]{1,4}\s?-?\s?){2}[0-9]{2}$" label="Numero de Contacto" name="user_phone" onChange={(e) => handleChange(e, "user_phone")} value={formData.user_phone} />
                                <Select

                                    defaultValue={selectedOption}
                                    placeholder={
                                        <div className="float-left text-blue-gray-500">Seleccione Especialidad</div>
                                    }
                                    styles={customStyles}
                                    options={options}
                                    value={selectedOption}
                                    onChange={(selected) => {
                                        handleChangeEspecialidad(selected);
                                        handleChange({ target: { value: selected.label, name: "vox_area", } });
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.label}
                                    name="vox_area"
                                />

                                <Select

                                    placeholder={
                                        <div className="float-left text-blue-gray-500">Seleccione Servicio</div>
                                    }
                                    styles={customStyles}
                                    options={selectedOption ? selectedOption.servicio : []}
                                    value={subSelectedOption}
                                    onChange={(selected) => {
                                        setSubSelectedOption(selected);
                                        handleChange({ target: { value: selected.label, name: "vox_service" } });
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.label}
                                    name="vox_service"
                                />
                                <div className="md:col-span-2">
                                    <Textarea type="text" required label="Agregue aquí información adicional de tu solicitud" name="message" onChange={(e) => handleChange(e, "message")} value={formData.message} />
                                </div>
                                <div className="md:col-span-2 md:mx-auto ">
                                    <Button type="submit" className="w-full h-12 flex items-center justify-center gap-2"><InboxArrowDownIcon className="w-5 h-5" />Enviar</Button>
                                </div>
                            </form>
                        </div>
                    </div >
                </section >
            </div>
        </>
    );
}
