import React from 'react';
import Navbar from '../components/navbar';
import Carousel from '../components/carousel';
import Features from '../components/features';
import Services from '../components/services';
import Footer from '../components/footer';
import Partners from '../components/partners';

const Homepage = () => {
  return (
    <div className="Home">
          <Navbar transparentNavbar={true} type="fixed" state={true} />
          <Carousel />
          <Features />
          <Services />
          <Partners />
          <Footer />
    </div>
  );
}

export default Homepage;