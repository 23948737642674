import React from 'react';
import Navbar from '../components/navbar';
import TIcontent from '../components/TIcontent';
import Footer from '../components/footer';

const TIpage = () => {
  return (
    <div className="TI">
          <Navbar transparentNavbar={false} type={"sticky"} />
          <TIcontent />
          <Footer />
    </div>
  );
}

export default TIpage;