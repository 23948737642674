import './assets/css/App.css';

//components
import Router from './Router';

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
